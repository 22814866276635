import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './dailyui.component.html',
  styleUrls: ['./dailyui.component.scss']
})
export class DailyuiComponent {

	scrollTop() {
		window.scrollTo(0, 0);
	}
	goToCodepen() {
    window.open('http://codepen.io/mcfresh/');
  }
  goToPenOne() {
  	window.open('http://codepen.io/mcfresh/pen/mWXjPZ');
  }
  goToPenTwo() {
  	window.open('http://codepen.io/mcfresh/pen/aJYyJr');
  }
  goToPenThree() {
  	window.open('http://codepen.io/mcfresh/pen/VpdaRz');
  }
  goToPenFour() {
  	window.open('http://codepen.io/mcfresh/pen/dvgpxw');
  }
}
