import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-page-home',
    templateUrl: './page-home.component.html',
    styleUrls: ['./page-home.component.scss']
})
export class PageHomeComponent implements OnInit {

    public showLetters: boolean = false;
    public edited = false;

    constructor(private route: ActivatedRoute) {
    }
    ngOnInit() {
        this.route.fragment.subscribe(f => {
            const element = document.querySelector("#" + f)
            if (element) element.scrollIntoView()
        })
    }
    scrollTop() {
        window.scrollTo(0, 0);
    }
    goToLinkedIn() {
        window.open('https://www.linkedin.com/in/mgcespedes/');
    }
    goToNewFolio() {
        window.open('https://mc-folio.vercel.app/');
    }
    goToCodepen() {
        window.open('https://codepen.io/manny_pixels');
    }
    goToGithub() {
        window.open('https://github.com/mgcesp');
    }
    goToDribbble() {
        window.open('https://dribbble.com/manolo');
    }
    goToTwitter() {
        window.open('https://twitter.com/manolo_ai')
    }
    goToSnapRaise() {
        window.open('https://snap-raise.com/home');
    }
    goToMicrosoft() {
        window.open('https://www.microsoft.com/en-us/');
    }
    goToT2() {
        window.open('https://health.mil/connectedhealth');
    }
    goToLeaflyPrototype() {
        window.open('https://framer.cloud/nonIT');
    }
    goToAllyStore() {
        window.open('https://itunes.apple.com/us/app/ally-for-alexa-voice-services/id1195730692?mt=8&ign-mpt=uo%3D4');
    }
    goToSnapShop() {
        window.open('https://www.oursnapshop.com/');
    }
}
