import { Component, OnInit } from '@angular/core';
import { EmailMessage } from '../model-interfaces/email.interface';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-page-contact',
  templateUrl: './page-contact.component.html',
  styleUrls: ['./page-contact.component.scss']
})
export class PageContactComponent implements OnInit {

	public emailMessage: EmailMessage;
	public showForm: boolean = true;
	public showSuccessMsg: boolean = false;

	constructor(private httpService: HttpService) {}

	ngOnInit() {
		this.emailMessage = {
			name: '',
			email: '',
			phone: '',
			message: ''
    };
	}
	scrollTop() {
    window.scrollTo(0, 0);
  }
	sendForm(form: EmailMessage, isValid: boolean) {
		console.log(form);
		this.httpService.postMessage(form)
			.subscribe(
				res => {
					console.log(res);
				}
			);
		this.showForm = false;
		this.showSuccessMsg = true;
	}
	// Event to restrict input to numbers
	numericOnly(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
	}
	goToFacebook() {
    	window.location.href='https://www.facebook.com/keesemediagroup/';
	}
	goToYoutube() {
		window.location.href='https://www.youtube.com/channel/UCX5N4obnJhYDTy2L9wlzUjQ';
	}
	goToInstagram() {
		window.location.href='https://www.instagram.com/dankeese/';
	}
}