import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './app-footer.component.html',
    styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {

    constructor() { }
    ngOnInit() { }

    scrollTop() {
        window.scrollTo(0, 0);
    }

    goToLinkedIn() {
        window.open('https://www.linkedin.com/in/mgcespedes/');
    }
    goToCodepen() {
        window.open('https://codepen.io/manny_pixels');
    }
    goToGithub() {
        window.open('https://github.com/mgcesp');
    }
    goToDribbble() {
        window.open('https://dribbble.com/manolo');
    }
    goToTwitter() {
        window.open('https://twitter.com/manolo_ai')
    }
    goToAngular() {
        window.open('https://angular.io/');
    }
}
