import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './project004.component.html',
    styleUrls: ['./project004.component.scss']
})
export class Project004Component implements OnInit {
    constructor() { }
    ngOnInit() { }

    scrollTop() {
        window.scrollTo(0, 0);
    }

    goToCaseStudyDeck() {
        window.open('https://pitch.com/public/56a9ff88-964c-4ddd-9f14-d2756494db45');
    }
}
