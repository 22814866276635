import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './project006.component.html',
  styleUrls: ['./project006.component.scss']
})
export class Project006Component {
	scrollTop() {
		window.scrollTo(0, 0);
	}
  goToSnapShop() {
    window.open('https://www.oursnapshop.com/');
  }
}