import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { DOCUMENT } from '@angular/platform-browser';

@Component({
	selector: 'app-header',
	templateUrl: 'app-header.component.html',
	styleUrls: ['app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

	public isMobile: boolean = false;
	public isDesktop: boolean = false;
	public showMobileMenu: boolean = false;
	public isScrolled: boolean = false;
	public deviceWidth: any;

	constructor(@Inject(DOCUMENT) private document: Document) { }

	public homeSelected:boolean = false;
	
	ngOnInit() { 
		this.showMobileMenu = false;
		this.checkDevice();
	}
	toggleMobileMenu() {
		this.showMobileMenu = !this.showMobileMenu;
	}
	hideMobileMenu() {
		this.showMobileMenu = false;
		window.scrollTo(0, 0);
	}
	toggleLogo() {
		this.homeSelected = !this.homeSelected;
	}

	@HostListener('window:scroll', ['$event'])
	onWindowScroll(event) {
		this.checkDevice();
	  if(this.showMobileMenu) {
			this.isScrolled = false;
		} else {
			let number = this.document.body.scrollTop;
			if (number > 1) {
		    this.isScrolled = true;
		  } else {
		  	this.isScrolled = false;
		  }
		}
	}
	@HostListener('window:resize', ['$event'])	
	onResize(event) {
		this.showMobileMenu = false;
		this.checkDevice();
	}
	checkDevice() {
		this.deviceWidth = window.innerWidth;
		if(this.deviceWidth <= 768) {
			this.isMobile = true;
		} else if (this.deviceWidth > 768) {
			this.isDesktop = true;
		}
	}
	resetDeviceType() {
		this.isDesktop = false;
		this.isMobile = false;
	}
}
