import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-about',
  templateUrl: './page-about.component.html',
  styleUrls: ['./page-about.component.scss']
})
export class PageAboutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  scrollTop() {
    window.scrollTo(0, 0);
  }
  
  goToResume() {
    window.open('https://drive.google.com/file/d/1x5tW3Om1pYyohD435XWMfy4E40GZuFys/view');
  }

  goToEmojiOne() {
  	window.open('http://emojione.com/');
  }

  goToAttain() {
  	window.open("http://www.attain.com/");
  }

  goToDevBros() {
		window.open("http://www.devbros.io/");
  }

}
