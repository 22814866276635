import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './project003.component.html',
    styleUrls: ['./project003.component.scss']
})
export class Project003Component implements OnInit {
    constructor() { }
    ngOnInit() { }

    scrollTop() {
        window.scrollTo(0, 0);
    }

    goToDesignSystemDeck() {
        window.open('https://pitch.com/public/a27741f6-03e9-4f06-a416-2b939b97bb25');
    }
}
