import { Component, OnInit, Input } from '@angular/core';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';

@Component({
  templateUrl: './project005.component.html',
  styleUrls: ['./project005.component.scss']
})
export class Project005Component {

  @Input()
  url: string = "https://framer.cloud/ZJUUR/";
  urlSafe: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

	scrollTop() {
		window.scrollTo(0, 0);
	}
  goToSnapRaise() {
    window.open('https://www.snap-raise.com/');
  }
}