import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './project001.component.html',
    styleUrls: ['./project001.component.scss']
})
export class Project001Component implements OnInit {
    constructor() { }
    ngOnInit() { }

    goToCaseStudy() {
        window.open('https://sepia-delivery-af0.notion.site/Evolving-a-design-system-aa8480331bd04858a50d3c0c5ee49a91');
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }
    goToFolioDeck() {
        window.open('https://pitch.com/public/56a9ff88-964c-4ddd-9f14-d2756494db45');
    }

}
