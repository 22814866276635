import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './project009.component.html',
  styleUrls: ['./project009.component.scss']
})
export class Project009Component implements OnInit {
  constructor() { }
  ngOnInit() { }

  scrollTop() {
		window.scrollTo(0, 0);
  }
  
  goToPrototypeA() {
    window.open('https://codepen.io/mcfresh/pen/RzLjRB');
  }
  
  goToPrototypeB() {
    window.open('https://codepen.io/mcfresh/pen/jjQrKP');
  }
}
