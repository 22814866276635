import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-resume',
  templateUrl: './page-resume.component.html',
  styleUrls: ['./page-resume.component.scss']
})
export class PageResumeComponent implements OnInit {

  constructor() {}

  ngOnInit() {}

  scrollTop() {
    window.scrollTo(0, 0);
  }

  goToResume() {
    window.open('https://drive.google.com/file/d/1bvYHVjGjYt9RDwVq9hVwqX-GfrykAf9Q/view?usp=sharing');
  }

  public jobs = [
    {
      'company': 'Foureyes',
      'position': 'Product Designer',
      'time': 'Oct 2020 - Current',
      'description': [
        'Created user interfaces and experiences for the Foureyes product, a sales intelligence platform used by businesses to track, engage, and sell better.',
      ]
    },
    {
      'company': 'Amazon, Microsoft',
      'position': 'Design Technologist',
      'time': 'Jan 2019 - Sep 2020',
      'description': [
        'Enhanced user experiences and user interfaces for the Amazon Brand Portal and M365 Admin Center.',

      ]
    },
    {
      'company': 'Snap! Raise',
      'position': 'Product Designer',
      'time': 'May 2017 - Dec 2018',
      'description': [
        'Crafted the design and user experience of several web and mobile applications used by coaches, parents, and students to fundraise for their clubs and sports teams.',
      ]
    },
	];

}
