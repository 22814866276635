import { NgModule }              from '@angular/core';
import { RouterModule, Routes }  from '@angular/router';

import { PageHomeComponent } from './page-home/page-home.component';
import { PageAboutComponent } from './page-about/page-about.component';
import { PageSkillsComponent } from './page-skills/page-skills.component';
import { PageContactComponent } from './page-contact/page-contact.component';
import { PageResumeComponent } from './page-resume/page-resume.component';

const appRoutes: Routes = [
  { path: 'home', component: PageHomeComponent },
  { path: 'about', component: PageAboutComponent },
  { path: 'skills', component: PageSkillsComponent },
  { path: 'resume', component: PageResumeComponent },
  { path: 'contact', component: PageContactComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home', pathMatch: 'full'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
