import { Component } from '@angular/core';
import * as _ from 'underscore';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app works!';
  // constructor() {
  //   const myArray: number[] = [9, 1, 5];
  //   const lastItem: number = _.last(myArray); //Using underscore
  //   console.log(lastItem); //5
  // }
}
