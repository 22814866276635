import { NgModule }              from '@angular/core';
import { RouterModule, Routes }  from '@angular/router';

import { PortfolioComponent } from './portfolio.component';

import { Project001Component } from './project001/project001.component';
import { Project002Component } from './project002/project002.component';
import { Project003Component } from './project003/project003.component';
import { Project004Component } from './project004/project004.component';
import { Project005Component } from './project005/project005.component';
import { Project006Component } from './project006/project006.component';
import { Project007Component } from './project007/project007.component';
import { Project008Component } from './project008/project008.component';
import { Project009Component } from './project009/project009.component';

import { DailyuiComponent } from './dailyui/dailyui.component';

const portfolioRoutes: Routes = [
  {
    path: 'portfolio',
    component: PortfolioComponent,
    children: [
      {
        path: '001',
        component: Project001Component
      },
      {
        path: '002',
        component: Project002Component
      },
      {
        path: '003',
        component: Project003Component
      },
      {
        path: '004',
        component: Project004Component
      },
      {
        path: '005',
        component: Project005Component
      },
      {
        path: '006',
        component: Project006Component
      },
      {
        path: '007',
        component: Project007Component,
      },
      {
        path: '008',
        component: Project008Component,
      },
      {
        path: '009',
        component: Project009Component,
      },
      {
        path: 'ui',
        component: DailyuiComponent,
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(portfolioRoutes)
  ],
  declarations: [
    Project001Component,
    Project002Component,
    Project003Component,
    Project004Component,
    Project005Component,
    Project006Component,
    Project007Component,
    Project008Component,
    Project009Component,
    DailyuiComponent
  ],
  exports: [
    RouterModule
  ]
})
export class PortfolioRoutingModule { }
