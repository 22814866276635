import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpService } from './services/http.service';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PortfolioRoutingModule } from './portfolio/portfolio-routing.module';
import { PortfolioComponent } from './portfolio/portfolio.component';

import { AppHeaderComponent } from './app-header/app-header.component';
import { PageHomeComponent } from './page-home/page-home.component';
import { PageSkillsComponent } from './page-skills/page-skills.component';
import { PageContactComponent } from './page-contact/page-contact.component';
import { PageResumeComponent } from './page-resume/page-resume.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { PageAboutComponent } from './page-about/page-about.component';


@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    PortfolioRoutingModule,
    AppRoutingModule
  ],
  declarations: [
    AppComponent,
    AppHeaderComponent,
    PageHomeComponent,
    PageSkillsComponent,
    PageContactComponent,
    PageResumeComponent,
    AppFooterComponent,
    PageAboutComponent,
    PortfolioComponent
  ],
  providers: [
    HttpService,
    {
      provide: LocationStrategy, 
      useClass: HashLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }